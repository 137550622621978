<template>
  <v-row justify="center" align="center" :style="'height:' + height + ';'">
    <div>
      <div v-if="icon" class="text-center mb-3">
        <v-icon :color="iconColor" large v-text="icon"></v-icon>
      </div>
      <div :class="'title text-center mb-1 ' + titleColor">{{ title }}</div>
      <div v-if="caption" :class="'body-2 text-center ' + captionColor">
        {{ caption }}
      </div>
      <div class="mt-8">
        <v-row class="justify-center mt-3">
          <slot></slot>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      required: true
    },
    titleColor: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    captionColor: {
      type: String,
      default: 'primary--text text--lighten-3'
    },
    height: {
      type: String,
      default: '250px'
    }
  }
}
</script>

<style></style>
